var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": ['column', 'row'],
      "justify-content": "center",
      "align-items": "center",
      "py": ['24px', '0px'],
      "px": ['0px', '24px'],
      "gap": ['0px', '64px']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "gap": "1rem",
      "width": ['100%', '500px']
    }
  }, [_c('c-image', {
    staticClass: "header__icon-dietela",
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/new_landing_page/logo-color@3x.webp?updatedAt=1689904664797",
      "alt": "icon logo dietela"
    }
  }), _c('c-image', {
    staticClass: "header__icon-login",
    attrs: {
      "src": _vm.iconLogin,
      "alt": "icon login dietela"
    }
  })], 1), _c('c-flex', {
    staticClass: "md:overflow-auto md:scrollbar-hide",
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "center",
      "width": ['100%', '520px'],
      "height": ['auto', '100vh'],
      "py": ['0px', '0px'],
      "px": ['16px', '24px']
    }
  }, [_c('h1', {
    staticClass: "title"
  }, [_vm._v(" Selamat Datang! ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "tab-header"
  }, [_c('a', {
    staticClass: "tab-header__item",
    class: _vm.$route.query.tab === 'login' ? 'tab-header__item--active' : '',
    on: {
      "click": function click($event) {
        return _vm.setTabActive('login');
      }
    }
  }, [_vm._v("Masuk")]), _c('a', {
    staticClass: "tab-header__item",
    class: _vm.$route.query.tab === 'signup' ? 'tab-header__item--active' : '',
    on: {
      "click": function click($event) {
        return _vm.setTabActive('signup');
      }
    }
  }, [_vm._v("Daftar")])]), _c('FormulateForm', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.tab === 'login',
      expression: "$route.query.tab === 'login'"
    }],
    staticClass: "tab-form",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "debounce": 50
    },
    on: {
      "submit": _vm.handleLogin
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var isValid = _ref.isValid,
            isLoading = _ref.isLoading;
        return [_c('FormulateInput', {
          attrs: {
            "data-testid": "input-email",
            "name": "email",
            "label": "Email",
            "icon-prefix": _vm.iconEmail,
            "icon-prefix-style": {
              width: '20px'
            },
            "placeholder": "Masukkan email",
            "validation": "required",
            "type": "text"
          },
          on: {
            "input": _vm.handleSessionEmail
          }
        }), _c('FormulateInput', {
          attrs: {
            "data-testid": "input-password",
            "name": "password",
            "label": "Kata Sandi",
            "icon-prefix": _vm.iconPassword,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan kata sandi",
            "validation": "required",
            "type": "password"
          }
        }), _c('div', {
          staticClass: "tab-form-action-1"
        }, [_c('c-flex', {
          attrs: {
            "align-items": "center",
            "justify-content": "center",
            "gap": "8px"
          }
        }, [_c('c-checkbox', {
          attrs: {
            "variant-color": "primary",
            "is-checked": _vm.isChecked
          },
          on: {
            "change": _vm.handleRememberMe
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['12px', '14px']
          }
        }, [_vm._v(" Ingat saya ")])], 1)], 1), _c('router-link', {
          attrs: {
            "to": "/forgot-password",
            "data-testid": "link-forgot-password"
          }
        }, [_c('div', {
          staticClass: "tab-form-action__link tab-form-action__link--hover"
        }, [_c('c-link', [_vm._v(" Lupa Kata Sandi? ")])], 1)])], 1), _c('div', {
          staticClass: "tab-form-action"
        }, [_c('c-box', {
          attrs: {
            "width": "100%"
          }
        }, [_c('d-button', {
          attrs: {
            "type": "submit",
            "disabled": !isValid || isLoading
          }
        }, [_vm._v(" Masuk ")]), _c('c-box', {
          attrs: {
            "text-align": "center",
            "margin-top": "8px",
            "margin-bottom": "8px",
            "pos": "relative"
          }
        }, [_c('c-box', {
          attrs: {
            "display": "block",
            "width": "100%",
            "height": "1px",
            "bg-color": "#C4C4C4",
            "pos": "absolute",
            "top": "50%",
            "left": "0",
            "margin-top": "-0.5px"
          }
        }), _c('c-box', {
          attrs: {
            "as": "span",
            "pos": "relative",
            "z-index": "1",
            "color": "#C4C4C4",
            "display": "inline-block",
            "bg-color": "white",
            "py": "6px",
            "px": "12px",
            "font-size": "12px"
          }
        }, [_vm._v(" atau ")])], 1), _c('d-button', {
          attrs: {
            "variant": "outlined",
            "disabled": _vm.disabled,
            "icon-start": _vm.iconGoogle
          },
          on: {
            "click": _vm.handleGoogleLogin
          }
        }, [_vm._v(" Masuk dengan Google ")])], 1), _c('p', {
          staticClass: "tab-form-action__link"
        }, [_vm._v(" Belum punya akun? " + _vm._s(" ") + " "), _c('a', {
          staticClass: "tab-form-action__link--hover",
          on: {
            "click": function click($event) {
              return _vm.setTabActive('signup');
            }
          }
        }, [_vm._v("Daftar")])])], 1)];
      }
    }]),
    model: {
      value: _vm.formValuesLogin,
      callback: function callback($$v) {
        _vm.formValuesLogin = $$v;
      },
      expression: "formValuesLogin"
    }
  }), _c('FormulateForm', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.tab === 'signup',
      expression: "$route.query.tab === 'signup'"
    }],
    staticClass: "tab-form",
    attrs: {
      "debounce": 50
    },
    on: {
      "submit": _vm.handleSignUp
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var isValid = _ref2.isValid,
            isLoading = _ref2.isLoading;
        return [_c('c-grid', {
          attrs: {
            "w": "100%",
            "template-columns": ['repeat(2, 1fr)'],
            "gap": ['8px', '16px'],
            "mx": "auto"
          }
        }, [_c('FormulateInput', {
          attrs: {
            "name": "firstname",
            "label": "Nama Depan",
            "icon-prefix": _vm.iconProfile,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Nama depan",
            "validation": "required",
            "type": "text"
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "lastname",
            "label": "Nama Belakang",
            "icon-prefix": _vm.iconProfile,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Nama belakang",
            "validation": "required",
            "type": "text"
          }
        })], 1), _c('FormulateInput', {
          attrs: {
            "name": "phone",
            "label": "Nomor Telepon",
            "icon-prefix": _vm.iconPhone,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan nomor telepon",
            "validation": "required|number",
            "type": "tel"
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "email",
            "label": "Email",
            "icon-prefix": _vm.iconEmail,
            "icon-prefix-style": {
              width: '20px'
            },
            "placeholder": "Masukkan email",
            "validation": "required|email",
            "type": "text"
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "password",
            "label": "Kata Sandi",
            "icon-prefix": _vm.iconPassword,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan kata sandi",
            "validation": "required|min:8,length|hasAlphaNum|hasSpecialCharacter|hasUpperCase|hasLowerCase",
            "type": "password"
          }
        }), _c('FormulateInput', {
          attrs: {
            "name": "password_confirm",
            "label": "Ulangi Kata Sandi",
            "icon-prefix": _vm.iconPassword,
            "icon-prefix-style": {
              marginTop: '-2px'
            },
            "placeholder": "Masukkan kata sandi",
            "validation": "required|confirm",
            "validation-name": "Password",
            "type": "password"
          }
        }), _c('div', {
          staticClass: "tab-form-action"
        }, [_c('d-button', {
          attrs: {
            "type": "submit",
            "disabled": !isValid || isLoading
          }
        }, [_vm._v(" Daftar ")]), _c('d-button', {
          attrs: {
            "variant": "outlined",
            "disabled": _vm.disabled,
            "icon-start": _vm.iconGoogle
          },
          on: {
            "click": _vm.handleGoogleSignup
          }
        }, [_vm._v(" Daftar dengan Google ")]), _c('p', {
          staticClass: "tab-form-action__link"
        }, [_vm._v(" Belum punya akun? " + _vm._s(" ") + " "), _c('a', {
          staticClass: "tab-form-action__link--hover",
          on: {
            "click": function click($event) {
              return _vm.setTabActive('login');
            }
          }
        }, [_vm._v("Masuk")])])], 1)];
      }
    }]),
    model: {
      value: _vm.formValues,
      callback: function callback($$v) {
        _vm.formValues = $$v;
      },
      expression: "formValues"
    }
  })], 1)], 1), _c('ModalEmailVerification', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isModalOpen,
      expression: "isModalOpen"
    }],
    attrs: {
      "is-open": _vm.isModalOpen,
      "email": _vm.formValues.email
    }
  }), _c('ModalCannotLogin', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoginError,
      expression: "isLoginError"
    }],
    attrs: {
      "is-open": _vm.isLoginError,
      "data": _vm.errorMessage
    },
    on: {
      "handle-close": _vm.handleCloseModalErrorCannotLogin
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }