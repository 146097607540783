<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="true"
    is-centered
    size="2xl"
    :on-close="close"
  >
    <c-modal-content
      ref="content"
      class="modalss"
      data-testid="modal-error-cannot-login"
    >
      <c-modal-body>
        <CImage
          :src="require('@/assets/images/illustration-1-raw.jpeg')"
          alt="success"
          pos="relative"
          z-index="1"
          mx="auto"
          mb="30px"
          h="230px"
        />
        <CHeading
          as="h1"
          color="primary.400"
          font-size="28px"
          font-weight="600"
          margin-bottom="30px"
          font-family="Roboto"
        >
          Login Gagal
        </CHeading>
        <CText
          color="darkGray.900"
          font-size="16px"
          line-hieght="27px"
          font-family="Roboto"
          margin-bottom="30px"
        >
          {{ data }}
        </CText>
        <d-button
          @click="close"
        >
          Oke
        </d-button>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import DButton from '@/components/elements/d-button.vue'
import iconHome from '@/assets/ic-home.svg'

export default {
  components: {
    DButton,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
    data: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconHome,
    }
  },
  methods: {
    close() {
      this.$emit('handle-close')
    },
  },
}
</script>
<style scoped>
.modalss::v-deep section {
  border-radius: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
}
</style>