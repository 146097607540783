var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": true,
      "is-centered": "",
      "size": "2xl",
      "on-close": _vm.close
    }
  }, [_c('c-modal-content', {
    ref: "content",
    staticClass: "modalss",
    attrs: {
      "data-testid": "modal-error-cannot-login"
    }
  }, [_c('c-modal-body', [_c('CImage', {
    attrs: {
      "src": require('@/assets/images/illustration-1-raw.jpeg'),
      "alt": "success",
      "pos": "relative",
      "z-index": "1",
      "mx": "auto",
      "mb": "30px",
      "h": "230px"
    }
  }), _c('CHeading', {
    attrs: {
      "as": "h1",
      "color": "primary.400",
      "font-size": "28px",
      "font-weight": "600",
      "margin-bottom": "30px",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Login Gagal ")]), _c('CText', {
    attrs: {
      "color": "darkGray.900",
      "font-size": "16px",
      "line-hieght": "27px",
      "font-family": "Roboto",
      "margin-bottom": "30px"
    }
  }, [_vm._v(" " + _vm._s(_vm.data) + " ")]), _c('d-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Oke ")])], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }